<template>
  <el-tabs
    v-model="activeName"
    tab-position="left"
    style="height: 100%;"
    type="border-card"
    @tab-click="handleClick"
  >
    <el-tab-pane label="公司基础信息" name="CompanyBasic">
      <CompanyBasic ref="CompanyBasicFrom" />
    </el-tab-pane>
    <el-tab-pane label="公司通勤" name="CompanyCommute">
      <CompanyCommute ref="CompanyCommuteFrom" />
    </el-tab-pane>
    <el-tab-pane label="公司资源配置" name="CompanySalaryConfig">
      <CompanySalaryConfig ref="CompanySalaryConfigFrom" />
    </el-tab-pane>
    <el-tab-pane label="工作待遇表" name="CompanyTreatment">
      <CompanyTreatment ref="CompanyTreatmentFrom" />
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import CompanyTreatment from "./modules/treatment/index";
import CompanyBasic from "./modules/basic/index";
import CompanySalaryConfig from "./modules/salaryConfig/index";
import CompanyCommute from "./modules/commute/index";

export default {
  components: {
    CompanyTreatment,
    CompanyBasic,
    CompanyCommute,
    CompanySalaryConfig
  },

  data() {
    return {
      tabPosition: "left",
      activeName: "CompanyBasic"
    };
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name == "CompanyBasic") {
        this.$refs.CompanyBasicFrom.initData();
      }
      if (tab.name == "CompanyCommute") {
        this.$refs.CompanyCommuteFrom.initData();
      }
      if (tab.name == "CompanySalaryConfig") {
        this.$refs.CompanySalaryConfigFrom.getDataListButton();
      }
      if (tab.name == "CompanyTreatment") {
        this.$refs.CompanyTreatmentFrom.selectcompanytreatmentButton();
      }
    }
  }
};
</script>