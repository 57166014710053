<template>
  <div>
    <el-card>
      <div style="float:right">
        <el-button
          type="primary"
          style="margin:-20px 20px 10px 10px"
          @click="editSalaryConfigButton"
        >保存</el-button>
      </div>
    </el-card>

    <el-table :data="tableData" ref="addEditForm">
      <el-table-column label="序号" align="center" width="56" fixed>
        <template slot-scope="scope">
          <el-radio :label="scope.$index" v-model="radio" @change.native="getCurrentRow(scope.row)"></el-radio>
        </template>
      </el-table-column>

      <el-table-column label="类型" align="center" fixed width="100">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-tag v-if="scope.row.type=='xun.personal.pension'">养老金基数</el-tag>
            <el-tag v-if="scope.row.type=='xun.personal.injury'">工伤基数</el-tag>
            <el-tag v-if="scope.row.type=='xun.personal.unemployment'">事业基数</el-tag>
            <el-tag v-if="scope.row.type=='xun.personal.medical'">医疗基数</el-tag>
            <el-tag v-if="scope.row.type=='xun.personal.birth'">生育基数</el-tag>
            <el-tag v-if="scope.row.type=='xun.personal.accum'">公积金基数</el-tag>
            <el-tag v-if="scope.row.type=='xun.personal.major'">重大医疗基数</el-tag>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column label="基数" align="center">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item>
              <el-input-number v-model="scope.row.base" @change="countMoney(scope.row)" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column label="比例" align="center">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item>
              <el-input-number v-model="scope.row.ration" @change="countMoney(scope.row)" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column label="金额" align="center">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item>
              <el-input-number v-model="scope.row.baseRation" @change="countMoney(scope.row)" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column label="公司比例" align="center">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item>
              <el-input-number v-model="scope.row.companyRation" @change="countMoney(scope.row)" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column label="公司金额" align="center">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item>
              <el-input-number
                v-model="scope.row.companyRationBase"
                @change="countMoney(scope.row)"
              />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="个人比例" align="center">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item>
              <el-input-number v-model="scope.row.myRation" @change="countMoney(scope.row)" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column label="个人金额" align="center">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item>
              <el-input-number v-model="scope.row.myRationBase" @change="countMoney(scope.row)" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import {
  getDataListApi,
  saveCompanysalaryconfigsApi
} from "@/api/my/company/companysalaryconfigs";
export default {
  data() {
    return {
      radio: "",
      tableData: []
    };
  },
  created() {
    this.getDataListButton();
  },
  methods: {
    getDataListButton() {
      getDataListApi().then(res => {
        this.tableData = res.result;
      });
    },
    countMoney(row) {
      row.baseRation = (row.base * row.ration).toFixed(2);
      row.companyRationBase = (row.baseRation * row.companyRation).toFixed(2);
      row.myRation=(1-row.companyRation).toFixed(2);
      row.myRationBase = (row.baseRation * row.myRation).toFixed(2);
    },
    editSalaryConfigButton() {
      saveCompanysalaryconfigsApi(this.tableData).then(res => {
        this.getDataListButton();
      });
    }
  }
};
</script>
<style scoped>
</style>